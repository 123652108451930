<script setup>
import IconLogoName2 from "./IconLogoName2.vue";
import { onMounted } from "vue";
import enterAnimation from "@/utils/enterAnimation.js";
import AraucariaLoader from "./AraucariaLoader.vue";
import { gsap } from "gsap";
import { setLoadingComplete } from "@/utils/store";
const grabScroll = () => {
  const scroll = document.querySelector(".loading-curtain");
  scroll.scrollTop = scroll.scrollHeight;
};
onMounted(() => {
  document.querySelector("body").classList.add("no-scroll");
  setTimeout(() => {
    document.querySelector(".araucania-name").style.clipPath = "inset(-100px)";
    setTimeout(() => {
      let interval = setInterval(() => {
        if (document.querySelector(".placeholder-img")?.complete) {
          gsap.to(".loading-curtain", {
            duration: 0.75,
            ease: "power3.inOut",
            height: 0,
          });
          enterAnimation();
          setLoadingComplete();
          clearInterval(interval);
        }
      }, 300);
    }, 300);
  }, 1500);
});
</script>

<script>
export default {
  name: "LandingLoader",
  props: {
    emprendedor: {
      type: Boolean,
      default: false,
    },
  },
  components: { AraucariaLoader },
};
</script>

<template>
  <div class="loading-curtain" @scroll="grabScroll()">
    <IconLogoName2 class="araucania-name px-5" fill="white"></IconLogoName2>
    <AraucariaLoader fill="white" class="araucaria-loader" id="curtain-loader"></AraucariaLoader>
  </div>
</template>

<style scoped>
.araucaria-loader {
  position: absolute;
  height: 40vh;
  left: 50vw;
  transform: translate(-50%, 60vh);
}

.loading-curtain {
  background-color: #ff1300;

  position: fixed;
  z-index: 9999;
  display: flex;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
}

.araucania-name {
  position: relative !important;
  top: 35vh;
  left: 50vw;
  width: 600px;
  height: 500px;
  transform: translate(-50%, -50%);
  clip-path: inset(0 100% 0 0);
  transition: clip-path 0.85s !important;
}
</style>
