<template>
  <svg
    width="140"
    height="50"
    viewBox="0 0 160 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.908 17.7171H31.8347V17.5513C33.5983 16.8911 34.7288 15.1004 34.7288 12.758C34.7288 8.98368 32.7723 6.77997 28.8864 6.77997H19.2697V26.8636H21.7778V19.122H32.2749V26.8636H34.7831V19.7279C34.7831 18.4045 34.3158 17.7171 32.911 17.7171H32.908ZM28.4161 16.9454H21.7748V9.01081H28.4161C30.7043 9.01081 32.082 10.0569 32.082 12.0677V13.8584C32.082 15.7305 30.7314 16.9454 28.4161 16.9454Z"
      :fill="fill"
    />
    <path
      d="M44.3698 6.77698L36.7939 26.8606H39.4107L41.1742 22.1216H50.4051L52.1687 26.8606H54.8698L47.2367 6.77698H44.3728H44.3698ZM49.6334 20.0566H41.946L43.2122 16.6138L45.7475 9.31231H45.8862L48.3672 16.6138L49.6334 20.0566Z"
      :fill="fill"
    />
    <path
      d="M70.1842 19.2034C70.1842 22.7306 68.0076 24.8227 64.592 24.8227C61.1764 24.8227 59.027 22.7275 59.027 19.2034V6.77698H56.5459V19.4235C56.5459 21.7659 57.2634 23.638 58.6682 25.0428C60.0731 26.4476 62.0567 27.138 64.592 27.138C67.1274 27.138 69.0567 26.4506 70.4887 25.0428C71.9207 23.638 72.6381 21.7628 72.6381 19.4235V6.77698H70.1872V19.2034H70.1842Z"
      :fill="fill"
    />
    <path
      d="M80.6783 10.5513C81.8902 9.36658 83.5151 8.78776 85.5289 8.78776C88.9717 8.78776 91.1754 10.6327 91.7271 13.4997H94.3167C94.0695 11.3774 93.159 9.67106 91.5884 8.40189C90.0449 7.13573 88.0341 6.49963 85.5832 6.49963C79.6323 6.49963 76.2438 10.3283 76.2438 16.8309C76.2438 23.3335 79.6323 27.135 85.3631 27.135C87.7869 27.135 89.7977 26.5019 91.3955 25.2629C92.9932 23.9967 93.9579 22.2874 94.3167 20.138H91.7271C91.0669 23.032 88.6702 24.877 85.4445 24.877C83.488 24.877 81.9174 24.2982 80.7055 23.1134C79.4936 21.9287 78.8876 20.3852 78.8876 18.5402V15.2904C78.8876 13.2796 79.4936 11.709 80.6783 10.5513Z"
      :fill="fill"
    />
    <path
      d="M102.691 6.77698L95.1155 26.8606H97.7322L99.4958 22.1216H108.727L110.49 26.8606H113.191L105.558 6.77698H102.694H102.691ZM107.955 20.0566H100.268L101.534 16.6138L104.069 9.31231H104.208L106.689 16.6138L107.955 20.0566Z"
      :fill="fill"
    />
    <path
      d="M129.003 17.6328L129.196 23.7224H129.058L118.395 6.77698H115.284V26.8637H117.789V15.7607L117.626 9.91826H117.734L128.37 26.8637H131.484V6.77698H129.003V17.6328Z"
      :fill="fill"
    />
    <path
      d="M143.055 1.57064L142.033 0L137.266 3.47289L138.038 4.65765L143.055 1.57064Z"
      :fill="fill"
    />
    <path d="M139.307 6.77698H136.799V26.8606H139.307V6.77698Z" :fill="fill" />
    <path
      d="M152.364 6.77698H149.5L141.924 26.8606H144.541L146.304 22.1216H155.535L157.299 26.8606H160L152.367 6.77698H152.364ZM147.073 20.0566L148.339 16.6138L150.875 9.31231H151.013L153.494 16.6138L154.76 20.0566H147.073Z"
      :fill="fill"
    />
    <path
      d="M6.52975 6.77698L0 12.8334V26.8637H2.5082V17.0449H12.8394V26.8637H15.3205V12.8334L8.79075 6.77698H6.53277H6.52975ZM12.8394 14.8955H2.5082V13.5208L7.54871 9.08923H7.79591L12.8364 13.5208V14.8955H12.8394Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  name: "icon-logo-name2",

  props: {
    width: {
      type: Number,
      default: 354,
    },
    height: {
      type: Number,
      default: 61,
    },
    fill: {
      type: String,
      default: "#000",
    },
  },
};
</script>
