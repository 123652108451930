<script setup>
import LandingLoader from "./components/LandingLoader.vue";
import LandingHeader from "./components/LandingHeader.vue";
import LandingExit from "./components/LandingExit.vue";
import { onMounted } from "vue";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();
onMounted(() => {
  window.__forceSmoothScrollPolyfill__ = true;
});
</script>
<template>

  <meta charset="UTF-8" />
  <title>Emprendimiento</title>
  <!-- <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous"> -->
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400&display=swap"
    rel="stylesheet">
  <link type="text/css" rel="stylesheet" href="styles/index.css" />
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link
    href="https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap"
    rel="stylesheet">


  <LandingHeader />
  <teleport to="body">
    <LandingLoader />
    <LandingExit />
  </teleport>
  <router-view />
</template>
