import { gsap } from "gsap";

export default function enterAnimation() {
  gsap.from(".navbar-brand", {
    opacity: 0,
    duration: 1,
    y: 50,
    ease: "power2.out",
    delay: 0.5,
  });
  gsap.from(".powered-by-rdx", {
    opacity: 0,
    duration: 1,
    y: 50,
    ease: "power2.out",
    delay: 0.65,
  });
  gsap.from("#nav-item1", {
    opacity: 0,
    duration: 1,
    y: 50,
    ease: "power2.out",
    delay: 0.65,
  });
  gsap.from("#nav-item2", {
    opacity: 0,
    duration: 1,
    y: 50,
    ease: "power2.out",
    delay: 0.8,
  });
  gsap.from(".date", {
    opacity: 0,
    duration: 1,
    y: 50,
    ease: "power2.out",
    delay: 0.95,
  });
  gsap.from(".time", {
    opacity: 0,
    duration: 1.25,
    y: 50,
    ease: "power2.out",
    delay: 1.05,
  });


  gsap.to("#curtain-loader", {
    duration: 1,
    opacity: 1,
    ease: "power2",
    delay: 0.5,
  });

  gsap.from(".hero-title", {
    y: 80,
    ease: "power2.out",
    delay: 1.65,
    letterSpacing: "0.7em",
    opacity: 0,
    scale: 0.75,
  });
  gsap.from(".hero-subtitle", {
    y: 80,
    ease: "power2.out",
    delay: 2,
    scale: 0.75,
    opacity: 0,
  });

  gsap.from("#tour-btn", {
    opacity: 0,
    y: 50,
    ease: "power2.out",
    delay: 1.2,
  });

  gsap.from("#tour-btn-text", {
    opacity: 0,
    duration: 1,
    y: 50,
    delay: 1.3,
    ease: "power2.out",
  });

  gsap.from("#tour-btn-icon", {
    opacity: 0,
    duration: 1,
    y: 80,
    delay: 1,
    ease: "power2.out",
  });

  setTimeout(() => {
    document.querySelector("body").classList.remove("no-scroll");
  }, 400);
}
