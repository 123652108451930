export const backgrounds = {
  video360:
    "https://d1g2o4pwbvyrni.cloudfront.net/projects/araucania/landing/bosque_rukakutral_landing_360.mp4",
  andina:
    "https://chileestuyo.cl/wp-content/uploads/2020/08/Portada-Araucania-andina.jpeg",
  temuco:
    "https://riolab.cdn.rdx.social/projects/araucania/landing/background-6.webp",
  costera:
    "https://riolab.cdn.rdx.social/projects/araucania/landing/background-7.webp",
  nahuelbuta:
    "https://riolab.cdn.rdx.social/projects/araucania/landing/background-4.webp",
  default:
    "https://riolab.cdn.rdx.social/projects/araucania/landing/background-5.webp",
};

export const texts = {
  pageName: "RDx Social",
  // welcomeMessage: '¡Bienvenidos a Araucanía!',
  // travelCTA: 'VIAJA AHORA',
  // visitors: "Visitantes",
  entrepreneurs: {
    sections: {
      devices: {
        text: "Sumérgete en un viaje inmersivo en 360° y recorre junto a sus habitantes, un territorio inexplorado y seductor",
      },
    },
  },
  visitors: {
    sections: {
      intro: {
        title: "CURARREHUE",
        subtitle: "Santuario de la naturaleza y la biodiversidad",
      },
      text: {
        title: "RDx Social",
        description:
          "Permite planificar la experiencia del viaje y conectarse con los emprendedores locales, y toda la riqueza de cada territorio. Un viaje a través de estaciones donde el usuario podrá interactuar con los atractivos del lugar, encontrar contenido personalizado, crónicas de viajes, pequeños documentales, historias y universo sonoro, con relatos capaces de inspirar y movilizar a las personas en la protección del medioambiente.",
      },
      scrolling: {
        text1:
          "Descubre nuestra gastronomía, cultura e historias de una tierra infinita.",
        background1:
          "https://riolab.cdn.rdx.social/projects/araucania/landing/background-1.webp",
        text2: "Sé parte de un turismo sostenible y diversificado.",
        text3: "Recorre las cinco zonas que dan forma a la región.",
        text4: "Conoce el patrimonio vivo de nuestras culturas ancestrales.",
      },
      map: {
        lacustre:
          "https://riolab.cdn.rdx.social/projects/araucania/landing/background-5.webp",
      },
    },
  },
};
