<script setup>
// TODO: Cambia todos los links a otras paginas por router-link, ya que es la forma correcta de hacerlo en vue y mejora el SEO de la pagina
import { ref, onMounted, onBeforeUnmount } from "vue";
import router from "@/router/index.js";
import { gsap } from "gsap";

// TODO: Crear una carpeta de iconos para que los tengas bien ordenados y separados de los componentes que vayas a reutilizar
import IconLogoName2 from "./IconLogoName2.vue";
import PoweredByRdx from "./PoweredByRdx.vue";

const scrolledPast = ref(false);

/* function changeThemeColor(newColor) {
  const themeColorMeta = document.querySelector('meta[name="theme-color"]');
  if (themeColorMeta) {
    themeColorMeta.setAttribute('content', newColor);
  } else {
    // Si no existe, puedes crear la etiqueta meta
    const newThemeColorMeta = document.createElement('meta');
    newThemeColorMeta.setAttribute('name', 'theme-color');
    newThemeColorMeta.setAttribute('content', newColor);
    document.head.appendChild(newThemeColorMeta);
  }
}
 */
const handleScroll = () => {
  scrolledPast.value = window.scrollY > window.innerHeight;


  /* if (scrolledPast.value) {
    changeThemeColor('#000000');
    // document.body.style.backgroundColor = '#000000'; // Cambia el color de fondo a negro
  } else {
    changeThemeColor('#FF1300');
    // document.body.style.backgroundColor = '#FF1300'; // Restaura el color de fondo original
  } */
};



const navigate = (route) => {
  gsap.to("#app", {
    opacity: 0.7,
    ease: "power3.inOut",
  });
  gsap.to(".landing-exit", {
    duration: 0.85,
    ease: "power3.inOut",
    height: "100vh",

    onComplete: () => {
      router.push(`/${route}`).then(() => {
        router.go(0);
      });
    },
  });
};

const currentRoute = () => {
  return window.location.pathname;
};
let currentTime = ref("");

let currentDate = ref("");
setInterval(() => {
  let date = new Date();
  currentTime.value = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  currentDate.value = date
    .toLocaleDateString("es-ES", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    })
    .toUpperCase()
    .split(",")
    .join(" ")
    .split("DE")
    .slice(0, 2)
    .join(" ");
}, 100);


onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  // setTimeout(() => {
  //   let tl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: ".interlude-section",
  //       start: "top 0%",
  //       end: "bottom 0%",
  //       scrub: true,
  //     },
  //   });

  // }, 1000);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>
<style lang="scss" scoped>
.nav-link:not(.nav-active) {
  color: white !important;
}

.nav-link.nav-inverse:not(.nav-active) {
  color: black !important;
}

.nav-active.nav-inverse {
  color: white !important;
  background-color: black !important;
}

.nav-active {
  color: black !important;
  background-color: white !important;
}
</style>
<template>
  <header data-scroll data-scroll-sticky data-scroll-target="#app"
    class="navbar navbar-expand-lg pt-2 py-md-2 px-3 px-md-5">
    <!-- :class="{ navbaremprendedor: currentPage == 2 }" -->
    <IconLogoName2 fill="white" class="navbar-brand ms-0 ms-md-2" v-if="!scrolledPast" />
    <IconLogoName2 fill="black" class="navbar-brand ms-0 ms-md-2" v-else />
    <!-- <PoweredByRdx class="powered-by-rdx" /> -->
    <div class="collapse navbar-collapse justify-content-center">
      <ul class="navbar-nav text-uppercase">
        <li class="nav-item me-5 active" id="nav-item1">
          <div to="" class="nav-link" @click="navigate('visitors')" :class="{
      'nav-active':
        currentRoute() == '/' || currentRoute() == '/visitors',
      'nav-inverse': scrolledPast,
    }">
            Visitors
          </div>
        </li>
        <li class="nav-item" id="nav-item2">
          <div to class="nav-link"
            :class="{ 'nav-active': currentRoute() == '/entrepreneurs', 'nav-inverse': scrolledPast }"
            @click="navigate('entrepreneurs')">
            Entrepreneurs
          </div>
        </li>
      </ul>
      <div></div>
    </div>

    <div class="d-flex flex-column date-time">
      <PoweredByRdx class="powered-by-rdx" :style="{ color: scrolledPast ? 'black' : 'white' }" />
      <!-- <p class="time mb-0" :style="{color:scrolledPast?'black':'white'}">{{ currentTime }}</p>
      <p class="date mb-0" :style="{color:scrolledPast?'black':'white'}">{{ currentDate }}</p> -->
    </div>

    <!-- <a class="mx-5 ms-0 account-btn" :class="{ disabled: currentPage == 1 }" href="/session/register">‎
        </a>
        <a class="account-btn" :class="{ disabled: currentPage == 1 }" href="/session/login">‎
        </a> -->
  </header>
  <div class="header-fill"></div>
</template>

<style lang="scss" scoped>
.header-fill {
  position: fixed;
  width: 100%;
  z-index: 460;
  top: min(5em, 15vh);
  height: 100%;
  transform: rotatex(180deg);
  transform-origin: top;
  background-color: #fafafa;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: min(5em, 15vh);
  width: 100%;
  z-index: 1000;
}

.nav-link {
  /* underline */
  font-weight: 500 !important;
  font-size: 0.75em;
  border-radius: 20px;
  letter-spacing: 0.1em;
  color: white;
  cursor: pointer;
}

.nav-active {
  background-color: white;

  color: black;
  border-radius: 5rem;
  padding: 0.4rem 1.25rem !important;
}

ul.navbar-nav {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-time {
  color: white;
  text-align: start;
  margin-left: 1em;
}

p.date {
  font-size: 0.75rem;
  font-weight: 300;
}

p.time {
  font-size: 1.3rem;
  font-weight: 300;
  margin-bottom: -0.25rem !important;
}

.navbar-brand {
  width: min(10rem, 40vw);
}
</style>
