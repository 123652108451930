<script>
export default {
  name: "araucaria-loader",
  props: {
    fill: {
      type: String,
      default: "white",
    },
  },
};
</script>

<template>
  <svg
    id="araucania"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 137 472"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
  >
    <g
      id="araucania-s-g1"
      transform="translate(.000002 0)"
      opacity="1"
      clip-path="url(#araucania-u-clip0_117_5190)"
    >
      <g id="araucania-s-g2">
        <g id="araucania-s-g3" opacity="0">
          <path
            id="araucania-s-path1"
            d="M36.3648,87.3088h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path2"
            d="M86.295,94.5833h-3.0841v7.2747h3.0841v-7.2747Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path3"
            d="M102.935,87.3088h-3.0839v7.2744h3.0839v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path4"
            d="M41.9146,87.3088h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path5"
            d="M47.4644,87.3088h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
        </g>
        <g id="araucania-s-g4" transform="translate(.000001 0)" opacity="0">
          <path
            id="araucania-s-path6"
            d="M80.7451,94.5833h-3.084v7.2747h3.084v-7.2747Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path7"
            d="M53.0054,94.5833h-3.084v7.2747h3.084v-7.2747Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path8"
            d="M97.3858,87.3088h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path9"
            d="M58.5552,94.5833h-3.084v7.2747h3.084v-7.2747Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path10"
            d="M91.836,87.3088h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path11"
            d="M3.08402,65.4775h-3.08402v7.2744h3.08402v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path12"
            d="M8.63382,72.7603h-3.08402v7.2743h3.08402v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path13"
            d="M30.8238,72.7603h-3.0841v7.2743h3.0841v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path14"
            d="M53.0054,80.0347h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path15"
            d="M136.216,65.4775h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path16"
            d="M122.042,80.0346v7.2743h3.084v-7.2743-7.2743h-3.084v7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path17"
            d="M110.942,80.0346v7.2743h3.084v-7.2743-7.2743h-3.084v7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path18"
            d="M130.667,72.7603h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
        </g>
        <g id="araucania-s-g5" transform="translate(0 0.000001)" opacity="0">
          <path
            id="araucania-s-path19"
            d="M119.576,80.0347h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path20"
            d="M108.477,72.7603h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path21"
            d="M11.0908,80.0346v7.2743h3.0923v-7.2743-7.2743h-3.0923v7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path22"
            d="M19.7246,80.0347h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path23"
            d="M22.1899,80.0346v7.2743h3.0841v-7.2743-7.2743h-3.0841v7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path24"
            d="M36.3648,72.7603h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path25"
            d="M102.935,72.7603h-3.0839v7.2743h3.0839v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path26"
            d="M97.3858,72.7603h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path27"
            d="M41.9146,72.7603h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path28"
            d="M91.836,65.4775h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path29"
            d="M86.295,80.0347h-3.0841v7.2743h3.0841v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path30"
            d="M72.1113,87.309v7.2743h3.084v-7.2743-7.2743h-3.084v7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path31"
            d="M58.5552,80.0347h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path32"
            d="M80.7451,80.0347h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
        </g>
        <g id="araucania-s-g6" transform="translate(0 0.000001)" opacity="0">
          <path
            id="araucania-s-path33"
            d="M61.0205,87.309v7.2743h3.084v-7.2743-7.2743h-3.084v7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path34"
            d="M102.935,58.2031h-3.0839v7.2744h3.0839v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path35"
            d="M11.0908,50.9289v7.2743h3.0923v-7.2743-7.2744h-3.0923v7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path36"
            d="M19.7246,50.9287h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path37"
            d="M25.274,58.2031h-3.0841v7.2744h3.0841v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path38"
            d="M30.8238,58.2031h-3.0841v7.2744h3.0841v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path39"
            d="M86.295,50.9287h-3.0841v7.2743h3.0841v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path40"
            d="M122.042,50.9289v7.2743h3.084v-7.2743-7.2744h-3.084v7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path41"
            d="M119.576,50.9287h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path42"
            d="M114.026,58.2031h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path43"
            d="M108.477,58.2031h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path44"
            d="M47.4644,65.4775h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path45"
            d="M41.9146,50.9287h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path46"
            d="M97.3858,50.9287h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
        </g>
        <g id="araucania-s-g7" transform="translate(.000001 0)" opacity="0">
          <path
            id="araucania-s-path47"
            d="M61.0205,43.6545v7.2743h3.084v-7.2743-7.2744h-3.084v7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path48"
            d="M33.2808,36.38v7.2744h3.084v-7.2744-7.2743h-3.084v7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path49"
            d="M114.026,29.1057h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path50"
            d="M105.393,29.1059v7.2743h3.092v-7.2743-7.2744h-3.092v7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path51"
            d="M99.8511,36.38v7.2744h3.0839v-7.2744-7.2743h-3.0839v7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path52"
            d="M41.9146,36.3801h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
        </g>
        <g id="araucania-s-g8" transform="translate(0 0.000001)" opacity="0">
          <path
            id="araucania-s-path53"
            d="M91.836,50.9287h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path54"
            d="M47.4644,50.9287h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path55"
            d="M53.0054,65.4775h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path56"
            d="M86.295,65.4775h-3.0841v7.2744h3.0841v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path57"
            d="M72.1113,65.4775v7.2828h3.084v-7.2828-7.2744h-3.084v7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path58"
            d="M61.0205,65.4775v7.2828h3.084v-7.2828-7.2744h-3.084v7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path59"
            d="M25.274,29.1057h-3.0841v7.2743h3.0841v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path60"
            d="M53.0054,50.9287h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
        </g>
        <g id="araucania-s-g9" opacity="0">
          <path
            id="araucania-s-path61"
            d="M72.1113,43.6545v7.2743h3.084v-7.2743-7.2744h-3.084v7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path62"
            d="M47.4644,36.3801h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path63"
            d="M49.9214,36.38v7.2744h3.084v-7.2744-7.2743h-3.084v7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path64"
            d="M55.4712,36.38v7.2744v7.2743v7.2743v7.2744v7.2828h3.084v-7.2828-7.2744-7.2743-7.2743-7.2744-7.2743h-3.084v7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path65"
            d="M97.3858,36.3801h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path66"
            d="M91.836,36.3801h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path67"
            d="M83.2109,36.38v7.2744h3.0841v-7.2744-7.2743h-3.0841v7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path68"
            d="M77.6611,36.38v7.2744v7.2743v7.2743v7.2744v7.2828h3.084v-7.2828-7.2744-7.2743-7.2743-7.2744-7.2743h-3.084v7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path69"
            d="M27.7397,29.1059v7.2743h3.0841v-7.2743-7.2744h-3.0841v7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path70"
            d="M61.0205,21.8314v7.2743h3.084v-7.2743-7.2828h-3.084v7.2828Z"
            :fill="fill"
          />
        </g>
        <g id="araucania-s-g10" opacity="0">
          <path
            id="araucania-s-path71"
            d="M97.3858,14.5486h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path72"
            d="M80.7451,14.5486h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path73"
            d="M47.4644,21.8315h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path74"
            d="M91.836,21.8315h-3.084v7.2744h3.084v-7.2744Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path75"
            d="M102.935,7.27441h-3.0839v7.27429h3.0839v-7.27429Z"
            :fill="fill"
          />
        </g>
        <g id="araucania-s-g11" transform="translate(0 0.000001)" opacity="0">
          <path
            id="araucania-s-path76"
            d="M58.5552,14.5486h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path77"
            d="M41.9146,14.5486h-3.084v7.2743h3.084v-7.2743Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path78"
            d="M36.3648,7.27441h-3.084v7.27429h3.084v-7.27429Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path79"
            d="M53.0054,7.27441h-3.084v7.27429h3.084v-7.27429Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path80"
            d="M91.836,0h-3.084v7.27433h3.084L91.836,0Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path81"
            d="M64.1045,0h-3.084v7.27433h3.084v-7.27433Z"
            :fill="fill"
          />
        </g>
        <g id="araucania-s-g12" opacity="0">
          <path
            id="araucania-s-path82"
            d="M72.1113,21.8314v7.2743h3.084v-7.2743-7.2828h-3.084v7.2828Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path83"
            d="M86.295,7.27441h-3.0841v7.27429h3.0841v-7.27429Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path84"
            d="M75.1953,0h-3.084v7.27433h3.084v-7.27433Z"
            :fill="fill"
          />
          <path
            id="araucania-s-path85"
            d="M47.4644,0h-3.084v7.27433h3.084v-7.27433Z"
            :fill="fill"
          />
        </g>
        <g
          id="araucania-s-path86_ts"
          transform="translate(68.108051,480) scale(1,0)"
        >
          <path
            id="araucania-s-path86"
            d="M66.1621,17.9804v10.9931v10.9804v10.9804v10.9803v10.9804v10.9804v10.9803v10.9933v10.98v10.981v10.98v10.98v10.981v10.98v10.993v10.981v10.98v10.98v10.981v10.98v10.98v224.394h3.8919v-224.394-10.98-10.98-10.981-10.98-10.98-10.981-10.993-10.98-10.981-10.98-10.98-10.981-10.98-10.9933-10.9803-10.9804-10.9804-10.9803-10.9804-10.9804-10.9931-10.9804h-3.8919v10.9804Z"
            transform="translate(-68.108051,-480)"
            :fill="fill"
          />
        </g>
      </g>
      <clipPath id="araucania-u-clip0_117_5190">
        <rect
          id="araucania-s-rect1"
          width="136.216003"
          height="472"
          rx="0"
          ry="0"
          :fill="fill"
        />
      </clipPath>
    </g>
  </svg>
</template>
<style>
#araucania-s-g3 {
  animation: araucania-s-g3_c_o 1800ms linear 1 normal forwards;
}

@keyframes araucania-s-g3_c_o {
  0% {
    opacity: 0;
  }

  48.888889% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

#araucania-s-g4 {
  animation: araucania-s-g4_c_o 1800ms linear 1 normal forwards;
}

@keyframes araucania-s-g4_c_o {
  0% {
    opacity: 0;
  }

  56.666667% {
    opacity: 0;
  }

  57.777778% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#araucania-s-g5 {
  animation: araucania-s-g5_c_o 1800ms linear 1 normal forwards;
}

@keyframes araucania-s-g5_c_o {
  0% {
    opacity: 0;
  }

  61.666667% {
    opacity: 0;
  }

  62.222222% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#araucania-s-g6 {
  animation: araucania-s-g6_c_o 1800ms linear 1 normal forwards;
}

@keyframes araucania-s-g6_c_o {
  0% {
    opacity: 0;
  }

  67.222222% {
    opacity: 0;
  }

  67.777778% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#araucania-s-g7 {
  animation: araucania-s-g7_c_o 1800ms linear 1 normal forwards;
}

@keyframes araucania-s-g7_c_o {
  0% {
    opacity: 0;
  }

  73.333333% {
    opacity: 0;
  }

  73.888889% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#araucania-s-g8 {
  animation: araucania-s-g8_c_o 1800ms linear 1 normal forwards;
}

@keyframes araucania-s-g8_c_o {
  0% {
    opacity: 0;
  }

  70.555556% {
    opacity: 0;
  }

  71.111111% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#araucania-s-g9 {
  animation: araucania-s-g9_c_o 1800ms linear 1 normal forwards;
}

@keyframes araucania-s-g9_c_o {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  76.111111% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#araucania-s-g10 {
  animation: araucania-s-g10_c_o 1800ms linear 1 normal forwards;
}

@keyframes araucania-s-g10_c_o {
  0% {
    opacity: 0;
  }

  77.222222% {
    opacity: 0;
  }

  77.777778% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#araucania-s-g11 {
  animation: araucania-s-g11_c_o 1800ms linear 1 normal forwards;
}

@keyframes araucania-s-g11_c_o {
  0% {
    opacity: 0;
  }

  81.666667% {
    opacity: 0;
  }

  82.777778% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#araucania-s-g12 {
  animation: araucania-s-g12_c_o 1800ms linear 1 normal forwards;
}

@keyframes araucania-s-g12_c_o {
  0% {
    opacity: 0;
  }

  89.444444% {
    opacity: 0;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#araucania-s-path86_ts {
  animation: araucania-s-path86_ts__ts 1800ms linear 1 normal forwards;
}

@keyframes araucania-s-path86_ts__ts {
  0% {
    transform: translate(68.108051px, 480px) scale(1, 0);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  100% {
    transform: translate(68.108051px, 480px) scale(1, 1);
  }
}
</style>
