import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import router from "./router";
import { backgrounds, texts } from './texts.js'
import VueLazyload from "vue-lazyload";
import './assets/styles/global.scss';

const pinia = createPinia();
const app = createApp(App);

app.use(VueLazyload)
app.use(pinia);
app.use(router);
app.mount("#app");

app.config.globalProperties.$backgrounds = backgrounds;
app.config.globalProperties.$texts = texts;

