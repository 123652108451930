<template>
  <div id="powered-by" class="d-flex flex-row">
    <p id="powered-by-text">Powered by</p>
    <svg
      width="96"
      height="33"
      viewBox="0 0 96 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1">
        <g clip-path="url(#clip0_1490_40461)">
          <path
            d="M39.9897 32.4492C39.9981 32.4359 40.0084 32.4245 40.0168 32.4113C40.0813 32.3098 40.129 32.2017 40.1617 32.0918C40.1645 32.0842 40.1673 32.0756 40.1692 32.0681C40.1757 32.0453 40.1804 32.0216 40.1851 31.9988C40.1888 31.9799 40.1944 31.9619 40.1972 31.9429C40.2001 31.9287 40.201 31.9154 40.2029 31.9012C40.2075 31.8728 40.2113 31.8434 40.2131 31.814C40.2131 31.8111 40.2131 31.8083 40.2131 31.8054C40.2272 31.6064 40.1963 31.4006 40.1131 31.2044L27.0986 0.772667C26.8985 0.304327 26.4432 0 25.9392 0H22.3646C21.8597 0 21.4034 0.304327 21.2033 0.773615L15.2436 14.7726L11.4941 12.3342C11.4652 12.3153 11.4352 12.2973 11.4044 12.2811C10.7873 11.9493 10.0215 12.1882 9.69424 12.814L0.145862 31.1181C0.0504908 31.303 0 31.5078 0 31.7163C0 32.4245 0.565684 32.9991 1.26414 32.9991H38.9565H38.9555C39.0416 32.9991 39.1257 32.9877 39.2089 32.9706C39.2108 32.9706 39.2117 32.9706 39.2136 32.9706C39.2538 32.9621 39.2931 32.9516 39.3314 32.9393C39.3352 32.9384 39.3398 32.9365 39.3436 32.9355C39.4922 32.8862 39.6297 32.8104 39.7512 32.7108C39.7643 32.7004 39.7774 32.69 39.7896 32.6796C39.8092 32.6615 39.8279 32.6435 39.8476 32.6236C39.8634 32.6075 39.8784 32.5914 39.8924 32.5753C39.9083 32.5573 39.9242 32.5392 39.9401 32.5203C39.9579 32.4975 39.9747 32.4738 39.9915 32.4501L39.9897 32.4492ZM11.3296 30.4336L16.3852 18.562L34.6395 30.4336H11.3296ZM23.1959 2.56639H25.1089L36.1449 28.3668L17.4006 16.1758L23.1959 2.56639ZM11.2875 15.2457L14.2281 17.158L8.57503 30.4355H3.36512L11.2875 15.2457Z"
            fill="currentColor"
          />
        </g>
        <g clip-path="url(#clip1_1490_40461)">
          <path
            d="M56.8938 16.25C58.5576 16.25 59.8591 16.641 60.7972 17.423C61.7354 18.205 62.2039 19.2762 62.2039 20.6365C62.2039 21.6528 61.9628 22.5444 61.4817 23.311C61.0006 24.0777 60.2293 24.6012 59.1689 24.8827V24.9298C59.674 25.024 60.0865 25.1806 60.4053 25.3986C60.723 25.6176 60.9767 25.8794 61.1641 26.1839C61.3515 26.4883 61.4897 26.829 61.58 27.2046C61.6691 27.5803 61.7388 27.9713 61.788 28.3776C61.82 28.784 61.8451 29.1991 61.8611 29.6207C61.8771 30.0424 61.9182 30.4575 61.9834 30.8638C62.0485 31.2702 62.1422 31.6579 62.2645 32.0248C62.3867 32.3928 62.5707 32.717 62.8153 32.9985H60.2213C60.0579 32.8265 59.9482 32.5921 59.8911 32.2953C59.834 31.9985 59.7974 31.6656 59.7814 31.2986C59.7654 30.9317 59.7482 30.5364 59.7323 30.1136C59.7163 29.6919 59.6671 29.2768 59.5848 28.8705C59.5197 28.4642 59.4374 28.0764 59.3403 27.7095C59.242 27.3426 59.0878 27.0217 58.8752 26.7479C58.6627 26.4741 58.3861 26.255 58.0433 26.0908C57.7005 25.9265 57.2434 25.8443 56.6732 25.8443H50.9963V32.9985H48.6709V16.25H56.8938ZM57.3829 23.8499C57.8719 23.7721 58.3004 23.6199 58.6673 23.3921C59.0341 23.1654 59.3277 22.8609 59.5483 22.4776C59.7688 22.0942 59.8785 21.5981 59.8785 20.988C59.8785 20.1436 59.634 19.4558 59.1449 18.9235C58.6558 18.3923 57.8639 18.1262 56.7715 18.1262H50.9963V23.9671H55.8425C56.3807 23.9671 56.8949 23.9288 57.384 23.8499H57.3829Z"
            fill="currentColor"
          />
          <path
            d="M72.4088 16.25C75.101 16.25 77.1728 16.9071 78.6251 18.2203C80.0775 19.5335 80.8031 21.5356 80.8031 24.2256C80.8031 25.6329 80.6397 26.8804 80.3141 27.9669C79.9872 29.0545 79.4822 29.969 78.7965 30.7116C78.1109 31.4542 77.2379 32.0215 76.1775 32.4125C75.117 32.8035 73.8601 32.9985 72.4088 32.9985H66.4131V16.25H72.4088ZM72.6042 31.1212C72.8648 31.1212 73.1916 31.1015 73.5835 31.0621C73.9755 31.0237 74.3914 30.9295 74.8314 30.7806C75.2713 30.6327 75.7078 30.417 76.1409 30.1355C76.5728 29.854 76.9602 29.4707 77.303 28.9866C77.6458 28.5025 77.9269 27.8957 78.1475 27.1685C78.368 26.4412 78.4777 25.5618 78.4777 24.53C78.4777 23.4983 78.376 22.6342 78.1715 21.8445C77.9669 21.0548 77.6333 20.3824 77.1682 19.8271C76.7031 19.2718 76.1032 18.8501 75.3696 18.5599C74.6348 18.2707 73.7298 18.1262 72.6534 18.1262H68.7373V31.1212H72.6042Z"
            fill="currentColor"
          />
          <path
            d="M83.1856 10.3359H85.8526L88.8877 14.5811L92.0449 10.3359H94.5406L90.1846 15.9184L95.0788 22.4625H92.4118L88.8877 17.443L85.3636 22.4625H82.8428L87.5907 16.0827L83.1856 10.3359Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1490_40461">
          <rect width="40.2188" height="33" fill="currentColor" />
        </clipPath>
        <clipPath id="clip1_1490_40461">
          <rect
            width="46.4062"
            height="28.875"
            fill="currentColor"
            transform="translate(48.6738 4.125)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
